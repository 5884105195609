import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { FormattedMessage } from 'react-intl';

const TeamFeatured = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          limit: 6
          filter: {
            frontmatter: { featured: { eq: "yes" } }
            fileAbsolutePath: { glob: "**/content/team/**/*.en.md" }
          }
          sort: { order: ASC, fields: [frontmatter___order] }
        ) {
          edges {
            node {
              fields {
                slug
              }
              id
              excerpt(pruneLength: 75)
              frontmatter {
                title
                path
                role
                facebook
                twitter
                instagram
                avatar {
                  childImageSharp {
                    fluid(maxWidth: 300, maxHeight: 300, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <section className="teamFeatured">
        <div className="container">
          <div className="row">
            <h2 className="col-lg-10 offset-sm-1 mb-3 mt-5">
              Family
              <a href="/en/team" className="btn btn-primary btn-lg float-right">
                See all members
              </a>
            </h2>
            {data.allMarkdownRemark.edges.map((post) => (
              <div className="col-6 col-md-4 p-1 p-md-4" key={post.node.id}>
                <Link to={post.node.frontmatter.path}>
                  <Img
                    fluid={post.node.frontmatter.avatar.childImageSharp.fluid}
                    alt="Furlan Snowboards"
                  />
                </Link>
                <h4 className="mt-3">
                  {post.node.frontmatter.title}
                  {!post.node.frontmatter.facebook == '' && (
                    <a
                      href={post.node.frontmatter.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook mx-1" />
                    </a>
                  )}
                  {!post.node.frontmatter.twitter == '' && (
                    <a
                      href={post.node.frontmatter.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter mx-1" />
                    </a>
                  )}
                  {!post.node.frontmatter.instagram == '' && (
                    <a
                      href={post.node.frontmatter.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram mx-1" />
                    </a>
                  )}
                </h4>
              </div>
            ))}
          </div>
        </div>
      </section>
    )}
  />
);

export default TeamFeatured;
