import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const RecentPosts = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          limit: 3
          filter: {
            fileAbsolutePath: { glob: "**/content/blog/**/*.en.md" }
            frontmatter: { status: { eq: "published" } }
          }
          sort: { order: DESC, fields: [frontmatter___date] }
        ) {
          edges {
            node {
              fields {
                slug
              }
              id
              excerpt(pruneLength: 75)
              frontmatter {
                title
                path
                tags
                author_id
                author_name
                date(formatString: "DD MMMM, YYYY")
                cover {
                  childImageSharp {
                    fluid(maxWidth: 300, maxHeight: 300, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <section className="blogRecentPosts">
        <div className="container pb-5 mt-3">
          <div className="row">
            <h2 className="col-lg-10 offset-sm-1 mb-">
              From the blog
              <a href="/en/blog" className="btn btn-primary btn-lg float-right">
                See all blog posts
              </a>
            </h2>
            {data.allMarkdownRemark.edges.map((post) => (
              <div className="col-sm-4 p-1 pb-4 p-md-4" key={post.id}>
                <Link to={post.node.frontmatter.path}>
                  <Img
                    fluid={post.node.frontmatter.cover.childImageSharp.fluid}
                    alt="Furlan Snowbords"
                  />
                  {post.node.frontmatter.tags &&
                    post.node.frontmatter.tags.map((tag) => {
                      const upperTag = tag.charAt(0).toUpperCase() + tag.slice(1);
                      return (
                        <span className="badge mt-3 badge-pill badge-primary float-right">
                          {upperTag}
                        </span>
                      );
                    })}
                  <h4 className="mt-3">{post.node.frontmatter.title}</h4>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    )}
  />
);

export default RecentPosts;
